import request from '@/utils/request';

// POST /sys/user/delete
//   删除用户
//
// GET /sys/user/info
// 获取登录的用户信息
//
// GET /sys/user/info/{userId}
// 根据Id获得用户信息
//
// GET /sys/user/list
// 获得用户列表
//
// POST /sys/user/password
// 修改密码
//
// POST /sys/user/save
// 保存用户
//
// POST /sys/user/update
// 修改用户

export function sysUserDelete(data) {
  return request({
    url: '/sys/user/delete',
    method: 'post',
    data,
  });
}

export function sysUserInfo() {
  return request({
    url: '/sys/user/info',
    method: 'get',
  });
}

export function sysUserPassword(data) {
  return request({
    url: '/sys/user/password',
    method: 'post',
    data
  });
}

export function sysUserInfoId(id) {
  return request({
    url: `/sys/user/info/${id}`,
    method: 'get'
  });
}

export function sysUserList(query) {
  return request({
    url: '/sys/user/list',
    method: 'get',
    params: query
  });
}

export function sysUserSave(data) {
  return request({
    url: '/sys/user/save',
    method: 'post',
    data
  });
}

export function sysUserUpdate(data) {
  return request({
    url: '/sys/user/update',
    method: 'post',
    data
  });
}

// POST /sys/role/delete
//   删除角色
//
// GET /sys/role/info/{roleId}
// 根据Id获得角色信息
//
// GET /sys/role/list
// 角色列表(分页)
//
// POST /sys/role/save
// 保存角色
//
// GET /sys/role/select
// 角色列表
//
// POST /sys/role/update
// 修改角色

export function sysRoleDelete(data) {
  return request({
    url: '/sys/role/delete',
    method: 'post',
    data
  });
}

export function sysRoleList(query) {
  return request({
    url: '/sys/role/list',
    method: 'get',
    params: query
  });
}

export function sysRoleInfo(roleId) {
  return request({
    url: `/sys/role/info/${roleId}`,
    method: 'get',
  });
}

export function sysRoleSave(data) {
  return request({
    url: '/sys/role/save',
    method: 'post',
    data,
  });
}

export function sysRoleUpdate(data) {
  return request({
    url: '/sys/role/update',
    method: 'post',
    data,
  });
}

export function sysRoleSelect(data) {
  return request({
    url: '/sys/role/select',
    method: 'get',
    // data,
  });
}

//
// /companyUser/list
// 用户分页列表
// POST /companyUser/setAccount
// 配置管理员
// POST /companyUser/updateStatus
// 启用/禁用
export function companyUserList(query) {
  return request({
    url: '/companyUser/list',
    method: 'get',
    params: query
    // data,
  });
}

export function companyUserSetAccount(data) {
  return request({
    url: '/companyUser/setAccount',
    method: 'post',
    data,
  });
}

export function companyUserUpdateStatus(data) {
  return request({
    url: '/companyUser/updateStatus',
    method: 'post',
    data,
  });
}
